jQuery(function ($) {
    $.validator.addMethod(
        'regex', 
        function(value, element) {
            var pattern = new RegExp($(element).attr('data-val-regex-pattern'));
            return this.optional(element) || pattern.test(value);
        }, 
        'Invalid password'
    );

    $.validator.unobtrusive.adapters.addBool('regex')
});